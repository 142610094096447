import React from 'react';

const Error = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 5L6 18" stroke="red" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6 5L19 18" stroke="red" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    );
};

export default Error;