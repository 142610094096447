import React from 'react';

const Key = ({selected}) => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.52148 22.4785L6.52148 20.4785M6.52148 20.4785L9.52148 23.4785L13.0215 19.9785L10.0215 16.9785M6.52148 20.4785L10.0215 16.9785M14.1315 12.8685C14.641 13.3849 15.2475 13.7953 15.9163 14.0763C16.585 14.3573 17.3027 14.5031 18.0281 14.5056C18.7535 14.508 19.4722 14.3669 20.1428 14.0905C20.8134 13.814 21.4227 13.4076 21.9356 12.8947C22.4485 12.3818 22.8549 11.7725 23.1314 11.1018C23.4079 10.4312 23.5489 9.71255 23.5465 8.98718C23.5441 8.26181 23.3982 7.54409 23.1172 6.87534C22.8363 6.20659 22.4258 5.60001 21.9095 5.09055C20.8722 4.08868 19.4829 3.53431 18.0408 3.54684C16.5987 3.55937 15.2192 4.1378 14.1995 5.15754C13.1797 6.17729 12.6013 7.55676 12.5888 8.99884C12.5762 10.4409 13.1306 11.8302 14.1325 12.8675L14.1315 12.8685ZM14.1315 12.8685L10.0215 16.9785" stroke={selected ? "#3da71d" : "#6f6f6f"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    );
};

export default Key;