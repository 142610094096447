import React from 'react';

const TableBottomBorder = ({color}) => {
    return (
        <svg y={1}>
            <g filter="url(#filter0_d_2862_35)">
                <path d="M5 5C5 2.23858 7.23858 0 10 0H200C202.761 0 205 2.23858 205 5V6H5V5Z" fill={color}/>
            </g>
            <defs>
                <filter id="filter0_d_2862_35" x="0.7" y="0" width="208.6" height="15.3" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feOffset dy="5"/>
                    <feGaussianBlur stdDeviation="2.15"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2862_35"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2862_35" result="shape"/>
                </filter>
            </defs>
        </svg>
    );
};

export default TableBottomBorder;