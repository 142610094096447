import React, {useEffect, useState} from 'react';
import st from "../../pages/Editor/Editor.module.css";

const TableHeader= ({text}) => {
    
    const [value, setValue] = useState("");

    const truncateText = (text, maxLength) => {
        // Если длина текста больше максимальной, обрезать и добавить "..."
        if (text.length > maxLength) {
            text = text.substring(0, maxLength) + '…';
        }
        setValue(text)
        // Возвращаем текст как есть, если он в пределах допустимой длины
    }
    
    useEffect(() => {
        truncateText(text, 24)
    }, [text])
    
    return (
        <svg width="200" height="37" x="5" y="7">
            <rect  width="200" height="36" fill="#E9F4FF" />
            <text x="0" y="0" dominantBaseline="middle" textAnchor="middle" fill="black" className={st.nonSelectable} style={{transform: "translate(50%, 55%)", fontSize: 13, fontWeight: "Bold", textOverflow: "ellipsis"}}>{value}</text>
            <rect y="36" width="200" height="1" fill="#D1D1D1" />
        </svg>
    );
};

export default TableHeader;