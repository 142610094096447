import React, {useState} from 'react';
import st from "./Register.module.css"
import {useForm} from "react-hook-form";
import {Redirect} from "react-router-dom";


export const Register = () => {
    
    const [isAuth, setIsAuth] = useState(false);
    
    const { register, handleSubmit, formState: {errors} } = useForm({
        defaultValues: {
            login: "",
            password: "",
            dublicate_password: "",
        },
        mode: "onChange"
    })

    const onSubmit = async (value) => {
        setIsAuth(true)
    }
    if (isAuth) {
        return <Redirect to="/admin/all" />
    }

    return (

        <div className={st.container}>
            <div className={st.authBlock}>
                <span style={{display: "inline-block", fontSize: 26, fontWeight: 400, marginBottom: 10}}>Регистрация</span>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <input
                        className={st.input}
                        type={"text"}
                        placeholder={"Логин"}
                        {...register("login", {
                            required: "Укажите логин",
                            minLength: {
                                value: 2,
                                message: "Минимум 3 символа"
                            }
                        })}
                    />
                    <div className={st.inputErrorMessage}>
                        {errors?.login && <p>{errors?.login?.message}</p>}
                    </div>
                    <input
                        className={st.input}
                        type={"password"}
                        placeholder={"Пароль"}
                        {...register("password", {
                            required: "Укажите пароль",
                            minLength: {
                                value: 2,
                                message: "Минимум 8 символов"
                            }
                        })}
                    />
                    <input
                        className={st.input}
                        type={"password"}
                        placeholder={"Повторите пароль"}
                        {...register("dublicate_password", {
                            required: "Укажите пароль",
                            minLength: {
                                value: 2,
                                message: "Минимум 8 символов"
                            }
                        })}
                    />
                    <div className={st.inputErrorMessage}>
                        {errors?.password && <p>{errors?.password?.message}</p>}
                    </div>
                    <div className={st.subButtonBox}>
                        <button type={"submit"} className={st.submitButton}>Создать</button>
                    </div>
                </form>
                {/*<Loader display={isLoading}/>*/}
            </div>

        </div>
    );
};

