import React from 'react';

const TableBorderSelect = ({x, y, height}) => {
    return (
        <svg x={x} y={y} width="1" height={height} viewBox={`0 0 1 ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="1" height={height} fill="#414AFF"/>
        </svg>
    );
};

export default TableBorderSelect;