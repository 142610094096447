import React from 'react';

const Plus = ({selected}) => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="13" y="4" width="2" height="20" rx="1" fill={selected ? "#295bd5" : "#A3A2A2"}/>
            <rect x="24" y="13" width="2" height="20" rx="1" transform="rotate(90 24 13)" fill={selected ? "#295bd5" : "#A3A2A2"}/>
        </svg>

    );
};

export default Plus;