import React from 'react';
import cn from "classnames";
import st from "../components/WorkBench/WorkBench.module.css";

const ArrowMenu = ({isOpenedWorkBenchMenu}) => {
    return (
        <svg className={cn(st.menuArrow, {[st.menuClosedArrow]: !isOpenedWorkBenchMenu})} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="8.80469" y="14.2192" width="2" height="11.276" rx="1" transform="rotate(-45 8.80469 14.2192)" fill="white"/>
            <rect x="16.7783" y="6.22192" width="2" height="11.2752" rx="1" transform="rotate(45 16.7783 6.22192)" fill="white"/>
        </svg>


    );
};

export default ArrowMenu;