import React, { useRef } from 'react';
import st from "../../pages/Editor/Editor.module.css";
import st2 from "./TableParts.module.css";
import MiniKey from "../MiniKey";

const TableField = ({deltaY, field, tableId, fieldId, selectRelationField, isPrimaryKey, isForeignKey}) => {
    const fieldNameTextRef = useRef();
    const fieldTypeTextRef = useRef();
    const padding = 10;
    
    return (
        <svg width="200" height="21" x="5" y={43 + 20 * deltaY}>
            <g className={st2.fieldGroup} onMouseUp={(e) => selectRelationField(e, tableId, fieldId)}>
                <rect x="0" y="1" width="200" height="20" className={st2.field}/>
                <g style={{transform: `translate(${padding}px, 22%)`, opacity: isPrimaryKey ? 1 : 0}}>
                    <MiniKey color={'#3DA71D'}/>
                </g>
                {isForeignKey &&
                    <g style={{transform: `translate(${padding}px, 22%)`}}>
                        <MiniKey color={'#3283cd'}/>
                    </g>
                }
                <text ref={fieldNameTextRef} x="0" y="0" dominantBaseline="middle" textAnchor="start" className={st.nonSelectable} style={{transform: `translate(${padding + (isPrimaryKey ? 15 : isForeignKey ? 15 : 0)}px, 55%)`, fontSize: 13, fill: "#828181"}}>{field.name}</text>
                <text ref={fieldTypeTextRef}
                          x="0" y="0" dominantBaseline="middle" textAnchor="end" className={st.nonSelectable} style={{transform: `translate(calc(100% - ${padding}px), 55%)`, fontSize: 13, fill: "#828181"}}>{field.type}</text>
            </g>
        </svg>
    );
};

export default TableField;