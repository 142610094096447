import React, {useEffect, useState} from 'react';
import st from "./TableParts.module.css";

const TableBorderSelectWithPoint = ({x, y, height, selectFieldPoint, fieldId, position, tables, tableId}) => {
    
    const [isPointUsed, setIsPointUsed] = useState(null);

    // useEffect(() => {
    //     setIsPointUsed(false)
    //     if (tables && Object.keys(tables[tableId].fields[fieldId].relations).length !== 0) {
    //         Object.values(tables[tableId].fields[fieldId].relations).forEach((value) => {
    //             if (value.usedPointPosition === position) {
    //                 setIsPointUsed(true)
    //             }
    //         });
    //     }
    // }, [tables])
    
    return (
        <svg x={x - 3.5} y={y} width="8" height={height}  fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="3.5" width="1" height={height} fill="#414AFF"/>
            <circle cx="4" cy={height / 2} r="4" fill={isPointUsed === true ? "#ff4141" : "#414AFF"} className={st.fieldPoint} onMouseDown={(e) => selectFieldPoint(e, fieldId, position)}/>
        </svg>
    );
};

export default TableBorderSelectWithPoint;