import React from 'react';

const Arrow = ({isOpened}) => {
    return (
        <svg style={{transform: isOpened ? "rotate(90deg)" : "rotate(-90deg)", transition: "all ease-in-out 300ms"}} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="8.80469" y="14.2192" width="1" height="11.276" rx="1" transform="rotate(-45 8.80469 14.2192)" fill="#545454"/>
            <rect x="16.7783" y="6.22192" width="1" height="11.2752" rx="1" transform="rotate(45 16.7783 6.22192)" fill="#545454"/>
        </svg>

    );
};

export default Arrow;