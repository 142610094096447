import React from 'react';

const TableBottomBorderSelect = ({x, y}) => {
    return (
        <svg x={x} y={y} width="203" height="7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 2.38419e-07V0.5C1 3.81371 3.68629 6.5 7 6.5H196C199.314 6.5 202 3.81371 202 0.5V2.38419e-07" stroke="#414AFF"/>
        </svg>
    );
};

export default TableBottomBorderSelect;