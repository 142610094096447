import React from 'react';

const MiniKey = ({color}) => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.93799 9.6337L2.79513 8.77655M2.79513 8.77655L4.08085 10.0623L5.58085 8.56227L4.29513 7.27655M2.79513 8.77655L4.29513 7.27655M6.05656 5.51513C6.2749 5.73642 6.53486 5.91233 6.82147 6.03274C7.10808 6.15314 7.41567 6.21567 7.72654 6.21671C8.03742 6.21775 8.34542 6.15729 8.63283 6.0388C8.92024 5.92032 9.18137 5.74615 9.40119 5.52633C9.62101 5.30651 9.79518 5.04538 9.91367 4.75797C10.0321 4.47056 10.0926 4.16255 10.0916 3.85168C10.0905 3.54081 10.028 3.23322 9.9076 2.94661C9.78719 2.66 9.61128 2.40004 9.38999 2.1817C8.94543 1.75233 8.35001 1.51474 7.73197 1.52011C7.11394 1.52548 6.52273 1.77338 6.0857 2.21041C5.64867 2.64744 5.40077 3.23865 5.3954 3.85668C5.39003 4.47472 5.62762 5.07014 6.05699 5.5147L6.05656 5.51513ZM6.05656 5.51513L4.29513 7.27655" stroke={color ? color : "#828181"} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>


    );
};

export default MiniKey;