import st from "./DevelopmentConsole.module.css";
import cn from "classnames";
import Arrow from "../../resources/Arrow";
import {useEffect, useRef, useState} from "react";
import Right from "../../resources/Right";
import Error from "../../resources/Error";
import {SaveMode} from "../../enum/SaveMode";
import {createNotification, NotificationStatus} from "../../utils/Notification";

const DevelopmentConsole = ({tables, setTables, relations, setRelations, isOpenedConsole, setIsOpenedConsole, saveToLocalStorage, projectId}) => {
    
    const [consoleTablesData, setConsoleTablesData] = useState("");
    const [consoleRelationsData, setConsoleRelationsData] = useState("");
    const [cursorPosition, setCursorPosition] = useState(0);
    const [isTableJsonValid, setIsTableJsonValid] = useState(true);
    const [isRelationsJsonValid, setIsRelationsJsonValid] = useState(true);
    const tablesTextAreaRef = useRef(null);
    const relationsTextAreaRef = useRef(null);
    const switchConsole = () => {
        setIsOpenedConsole(!isOpenedConsole)
        setConsoleTablesData(formatJson(JSON.stringify(tables)))
        setConsoleRelationsData(formatJson(JSON.stringify(relations)))
        validateJson(JSON.stringify(tables), "tables")
        validateJson(JSON.stringify(relations), "relations")
    }


    const formatData = () => {
        setConsoleTablesData(formatJson(consoleTablesData.replaceAll(" ", "").replaceAll("\n", "")))
        setConsoleRelationsData(formatJson(consoleRelationsData.replaceAll(" ", "").replaceAll("\n", "")))
    }
    
    const exportData = () => {
        const question = "Сохранить локально схему перед экспортом ?";
        // eslint-disable-next-line no-restricted-globals
        const needSave = confirm(question);
        if (needSave) {
            saveData()
        }
        const projectToExport = localStorage.getItem("project_" + projectId)
        const blob = new Blob([projectToExport], {type: "application/json"});
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `project_${projectId}.json`; // Название файла
        link.click(); // Инициирует скачивание
        URL.revokeObjectURL(url); // Очистка ресурса после загрузки
        createNotification(NotificationStatus.SUCCESS, "Данные успешно экспортированы")
    }

    const importData = (event) => { // TODO добавить проверку сохраненых данных (подключить аллерты) и сохранять данные после валидации сразу в localStorage 
        const file = event.target.files[0];
        event.target.value = '';
        const reader = new FileReader();
        reader.onload = function(e) {
            try {
                const content = JSON.parse(e.target.result.replaceAll(" ", "").replaceAll("\n", ""));
                const tables = content?.tables ? content?.tables : {};
                if (!content?.tables) {
                    createNotification(NotificationStatus.ERROR, "Не найден атрибут tables", "Ошибка импорта данных")
                    return
                }
                const relations = content?.relations ? content?.relations : {};
                if (!content?.relations) {
                    createNotification(NotificationStatus.ERROR, "Не найден атрибут relations", "Ошибка импорта данных")
                    return
                }
                setTables(tables)
                setRelations(relations)
                createNotification(NotificationStatus.SUCCESS, "Данные успешно импортированы")
                
            } catch (e) {
                console.error(e)
              createNotification(NotificationStatus.ERROR, "", "Ошибка импорта данных")  
            }
        };
        if (file) {
            reader.readAsText(file);
        }
    };
    
    const saveData = () => {
        if (isTableJsonValid && isRelationsJsonValid) {
            setTables(JSON.parse(consoleTablesData));
            setRelations(JSON.parse(consoleRelationsData));
            saveToLocalStorage(SaveMode.ALL, "saveData (Dev Console)")
        }
        else if (isTableJsonValid) {
            setTables(JSON.parse(consoleTablesData))
            saveToLocalStorage(SaveMode.TABLES, "saveData (Dev Console)")
        }
        else if (isRelationsJsonValid) {
            setRelations(JSON.parse(consoleRelationsData))
            saveToLocalStorage(SaveMode.RELATIONS, "saveData (Dev Console)")
        }
    }

    const changeData = (e, key) => {
        validateJson(e.target.value, key)
        let data;
        if (key === "tables") {
            data = consoleTablesData;
        } else {
            data = consoleRelationsData;
        }
        
        // Получаем текущую позицию курсора
        let newCharIndex = null;
        if (e.target.value.length < data.length) {
            setCursorPosition(key === "tables" ? tablesTextAreaRef.current.selectionStart : relationsTextAreaRef.current.selectionStart);
        } else {
            const consoleJson =  data.replaceAll(" ", "").replaceAll("\n", "");
            const newJson =  e.target.value.replaceAll(" ", "").replaceAll("\n", "");
            for (let i = 0; i < consoleJson.length; i++) {
                if (consoleJson[i] !== newJson[i]) {
                    newCharIndex = i
                    break
                }
            } 
        }
        // после изменения json идет переформирование строки с пробелами и переносами, поэтому передаем позицию курсора
        if (key === "tables") {
            setConsoleTablesData(formatJson(e.target.value.replaceAll(" ", "").replaceAll("\n", ""), newCharIndex))
        } else {
            setConsoleRelationsData(formatJson(e.target.value.replaceAll(" ", "").replaceAll("\n", ""), newCharIndex))
        }
        
    }
    
    useEffect(() => {
        tablesTextAreaRef.current.setSelectionRange(cursorPosition, cursorPosition);
        relationsTextAreaRef.current.setSelectionRange(cursorPosition, cursorPosition);
    }, [consoleTablesData, consoleRelationsData, cursorPosition])
    
    const validateJson = (json, key) => {
      try {
          JSON.parse(json)
          key === "tables" ? setIsTableJsonValid(true) : setIsRelationsJsonValid(true)
          
      } catch (e) {
          key === "tables" ? setIsTableJsonValid(false) : setIsRelationsJsonValid(false)
      }
    }
    
    const [lastMouseMoveTime, setLastMouseMoveTime] = useState(0);
    useEffect(() => {
        const currentTime = Date.now();
        const isAvailableByTime = currentTime - lastMouseMoveTime > 100;
        if (isAvailableByTime) {
            setConsoleRelationsData(formatJson(JSON.stringify(relations)))
            setConsoleTablesData(formatJson(JSON.stringify(tables)))
            setLastMouseMoveTime(currentTime);
        }
    }, [tables, relations])
    
    const formatJson = (json, newCharIndex = null) => {
        let formattedJson = "";
        let space = "   ";
        let level = 0
        for (let i = 0; i < json.length; i++) {
            if (json[i] === "{") {
                formattedJson += "\n" + space.repeat(level) + json[i] + "\n" + space.repeat(level + 1)
                level++
            } else if (json[i] === ",") {
                formattedJson += json[i] + "\n" + space.repeat(level)
            } else if (json[i] === "}") {
                formattedJson += "\n" + space.repeat(level - 1) + json[i]
                level--
            }
            else {
                formattedJson += json[i]
            }
            // сетим позицию курсора
            if (newCharIndex === i) {
                setCursorPosition(formattedJson.length)
            }
        }
        return formattedJson;
    }
    
    return (
        <div className={st.consoleBlock}>
            <div className={st.consoleHeader} onClick={switchConsole}>
                <Arrow isOpened={isOpenedConsole}/>
                <span>Developer console</span>
            </div>
            <div className={cn(st.editorBlockClosed, {[st.editorBlockOpened]: isOpenedConsole})}>
                <span>Tables</span>
                <div className={st.tablesEditorBlock}>
                    <div className={st.indicatorBlock}>
                        {isTableJsonValid === true && isOpenedConsole &&
                            <Right/>
                        }
                        {isTableJsonValid === false && isOpenedConsole &&
                            <Error/>
                        }
                    </div>
                    <textarea ref={tablesTextAreaRef} spellCheck="false" className={st.editor} value={consoleTablesData} onChange={(e) => changeData(e, "tables")}/>
                </div>
                <span>Relations</span>
                <div className={st.tablesEditorBlock}>
                    <div className={st.indicatorBlock}>
                        {isRelationsJsonValid === true && isOpenedConsole &&
                            <Right/>
                        }
                        {isRelationsJsonValid === false && isOpenedConsole &&
                            <Error/>
                        }
                    </div>
                    <textarea ref={relationsTextAreaRef} spellCheck="false" className={st.editor} value={consoleRelationsData} style={{height: "30vh"}} onChange={(e) => changeData(e, "relations")}/>
                </div>
                <div className={st.buttonsBlock}>
                    <button className={st.closeButton} onClick={switchConsole}>Cancel</button>
                    <button className={st.closeButton} onClick={formatData}>Format</button>
                    <button className={st.closeButton} onClick={exportData}>Export</button>  {/* TODO стилизовать кнопки */}
                    <label className={st.importButtonLabel} htmlFor="file-loader" >Import</label>
                    <input
                        id="file-loader"
                        type="file"
                        className={st.importButton}
                        accept=".json"
                        onChange={importData}
                    />
                    <button className={cn(st.saveButton, {[st.saveButtonDisabled]: !isTableJsonValid || !isRelationsJsonValid} )} onClick={saveData}>Save</button>
                </div>
            </div>
        </div>
    )
}

export default DevelopmentConsole;
