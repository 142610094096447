import st from "./WorkBench.module.css";
import {useEffect, useState} from "react";
import cn from "classnames";
import Options from "../../resources/Options";
import TableArrow from "../../resources/TableArrow";
import ArrowMenu from "../../resources/ArrowMenu";
import { v4 as uuidv4 } from 'uuid';
import Plus from "../../resources/Plus";
import Trash from "../../resources/Trash";
import {FieldTypes} from "../../enum/FielsTypes";
import Key from "../../resources/Key";
import { HexColorPicker } from "react-colorful";

const WorkBench = ({tables, createTable, selectTable, selectedTableId, updateTables, deleteTable, deleteField, updateFieldType}) => {

    const [isOpenedWorkBenchMenu, setIsOpenedWorkBenchMenu] = useState(true);
    const [openedTableIds, setOpenedTableIds] = useState(new Set());
    const [tableMenu, setTableMenu] = useState({
        top: 0,
        left: 0,
        tableId: null,
        isChangingColor: false
    });
    const [projectId, setProjectId] = useState(1)
    let [colors, setColors] = useState([]) // цвета в памяти браузера

    const updateTableColor = (e, needSaveToLocalStorage) => {
        if (selectedTableId) {
            if (!needSaveToLocalStorage) {
                tables[selectedTableId].color = e;
            }
            updateTables(tables, "updateTableColor", needSaveToLocalStorage)
        }
    }
    
    const selectSavedTableColor = (color) => {
        tables[selectedTableId].color = color;
        updateTables(tables, "selectSavedTableColor")
    }
    
    const switchWorkBench = () => {
        setTableMenu({
            top: 0,
            left: 0,
            tableId: null,
            isChangingColor: false
        })
        setIsOpenedWorkBenchMenu(!isOpenedWorkBenchMenu);
    }
    
    const selectObject = (index) => {
        selectTable(null, index)
    }

    const copyTable = (e, tableId) => {
        e.stopPropagation()
        createTable(390, 50, {...tables[tableId], name: tables[tableId].name + "_copy"});
    }

    const switchTable = (tableId) => {
        if (openedTableIds.has(tableId)) {
            openedTableIds.delete(tableId)
        } else {
            openedTableIds.add(tableId)
        }
        setOpenedTableIds(new Set(openedTableIds))
    }

    const openTable = (tableId) => {
        if (!openedTableIds.has(tableId)) {
            openedTableIds.add(tableId)
        }
    }
    
    const switchMenu = (e, tableId) => { // TODO переделать на switchMenu
        if (tableMenu.tableId && tableMenu.tableId === tableId) {
            setTableMenu({})
        } else  {
            setTableMenu({
                top: e.target.getBoundingClientRect().y,
                left: e.target.getBoundingClientRect().right + 10,
                tableId: tableId
            })
        }
    }
    
    const createField = (tableId) => {
        tables[tableId].fields[uuidv4()] = {
            name: "",
            type: "",
            relations: [],
            constraints: []
        }
        updateTables(tables, "createField")
        openTable(tableId)
    }

    const switchPrimaryKey = (tableId, fieldId) => {
        if (tables[tableId].fields[fieldId]?.constraints.includes(Constraints.PRIMARY_KEY)) {
            tables[tableId].fields[fieldId].constraints = tables[tableId].fields[fieldId].constraints.filter(constraint => constraint !== Constraints.PRIMARY_KEY);
        } else {
            tables[tableId].fields[fieldId].constraints.push(Constraints.PRIMARY_KEY);
        }
        updateTables(tables, "switchPrimaryKey")
    }

    const changeTableName = (tableId, value) => {
        tables[tableId].name = value
        updateTables(tables, "changeTableName")
    }
    
    const changeFieldName = (tableId, fieldId, value) => {
        tables[tableId].fields[fieldId].name = value
        updateTables(tables, "changeFieldName")
    }

    const createObject = () => {
        // TODO сделать управление размерами панели
        createTable(390,
            50);
    }
    
    const switchColorPalette = () => {
        setTableMenu({...tableMenu, isChangingColor: !tableMenu.isChangingColor})
    }
    
    const saveColor = () => {
        colors.reverse().push(tables[selectedTableId].color)
        localStorage.setItem("savedColors" + projectId, JSON.stringify(colors));
        setColors([...colors].reverse().slice(0, 10))
    }
    
    useEffect(() => {
        const savedColors = localStorage.getItem('savedColors' + projectId);
        if (JSON.parse(savedColors)) {
            setColors([...JSON.parse(savedColors)].reverse())
        }
    }, [])
    
    useEffect(() => {
        if (selectedTableId === null) {
            setTableMenu({})
        } else if (selectedTableId !== tableMenu.tableId) {
            setTableMenu({})
        }
    },[selectedTableId])
    
    return (
        <div className={cn(st.workBenchBlock, {[st.workBenchBlockSwitched]: !isOpenedWorkBenchMenu})}>
            <div className={cn(st.workBenchCloseButton, {[st.workBenchCloseButtonSwitched]: !isOpenedWorkBenchMenu})} onClick={switchWorkBench}><ArrowMenu isOpenedWorkBenchMenu={isOpenedWorkBenchMenu}/></div>
            <div className={st.workBenchHeaderBlock}>
                <div className={st.workBenchTitleBlock}>
                    <span className={st.nonSelectable}>WorkBench</span>
                </div>
                <div className={st.workBenchButtons}>
                    <span className={st.nonSelectable}>Tables</span>
                    <div className={st.workBenchCreateButton} onClick={createObject}>
                        <span className={st.nonSelectable}>+ New Table</span>
                    </div>
                </div>
            </div>
            <div className={st.workBenchBodyBlock}>
                {Object.keys(tables).map((tableId) => (
                    <div className={st.tableBlock} key={tableId}>
                        <div className={cn(st.tableHeader, {[st.tableHeaderSelected]: tableId === selectedTableId})} onClick={() => selectObject(tableId)}>
                            <div className={st.tableColorMark}  style={{backgroundColor: `${tables[tableId].color}`}}></div>
                            <div className={st.openButtonNNameBlock}>
                                <div className={st.tableOptionButton} onClick={() => switchTable(tableId)}><TableArrow selected={tableId === selectedTableId} tableId={tableId} openedTableIds={openedTableIds}/></div>
                                <input className={st.tableNameInput} value={tables[tableId].name} onChange={(event) => changeTableName(tableId, event.target.value)}/>
                            </div>
                            <div className={st.tableOptions}>
                                <div className={st.tableOptionButton} onClick={() => createField(tableId)}><Plus selected={tableId === selectedTableId}/></div>
                                <div className={st.tableOptionButton} onClick={(e) => switchMenu(e, tableId)}><Options selected={tableId === selectedTableId}/></div>
                                {tableId === tableMenu.tableId && selectedTableId &&
                                    <>
                                        <div className={st.menuBlock} style={{top: tableMenu.top, left: tableMenu.left}}>
                                            <div className={st.menuItem} onClick={(e) => createField(tableId)}>
                                                <span>Add field</span>
                                            </div>
                                            <div className={st.menuItem} onClick={(e) => copyTable(e, tableId)}>
                                                <span>Copy table</span>
                                            </div>
                                            <div className={cn(st.menuItem, {[st.menuItemSelected]: tableMenu.isChangingColor})} onClick={(e) => switchColorPalette(e, tableId)}>
                                                <span>Change color</span>
                                            </div>
                                            <div className={st.menuItem} onClick={(e) => deleteTable(e, tableId)}>
                                                <span>Delete table</span>
                                            </div>
                                        </div>
                                        {tableMenu.isChangingColor &&
                                            <>
                                                <div className={st.favColorsBlock} style={{top: tableMenu.top + 105, left: tableMenu.left}}>
                                                    <div className={st.saveColorButton} onClick={saveColor}>
                                                        <span>Save color</span>
                                                    </div>
                                                    <div className={st.colorPalette}>
                                                        {[...Array(10)].map((colorIndex, id) => (
                                                            <div key={id} className={st.color} onClick={() => selectSavedTableColor(colors[id])} style={{backgroundColor: colors[id] ? colors[id] : ''}}></div>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div onMouseUp={() => updateTableColor(null, true)} className={st.paletteBlock} style={{top: (tableMenu.top ? tableMenu.top : 0), left: `${(tableMenu.left ? tableMenu.left : 0) + 155}px`, position: "fixed"}}>
                                                    <HexColorPicker color={tables[selectedTableId].color} onChange={(e) => updateTableColor(e, false)} />
                                                </div>
                                            </>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                        <div className={cn(st.tableFieldsBlock, {[st.tableFieldsBlockOpened]: openedTableIds.has(tableId)})} style={{overflowY: Object.keys(tables[tableId].fields).length * 40 > 500 ? "auto" : "hidden"}}>
                            {Object.keys(tables[tableId].fields).map((fieldId) => (
                                <div key={fieldId} className={st.tableFieldBlock}>
                                    <div className={st.tableColorMark}  style={{backgroundColor: `${tables[tableId].color}`}}></div>
                                    
                                    <div className={st.inputBlock}>
                                        <input className={st.fieldNameInput} value={tables[tableId].fields[fieldId].name} onChange={(event) => changeFieldName(tableId, fieldId, event.target.value)}/>
                                        <select value={tables[tableId].fields[fieldId].type} className={st.fieldTypeInput} onChange={(event) => updateFieldType(tableId, fieldId, event.target.value)}>
                                            {Object.entries(FieldTypes).map(([key, value]) => (
                                                <option key={key} value={value}>{value}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className={st.tableOptions}>
                                        <div className={cn(st.tableOptionButton, {[st.primaryKey]: !tables[tableId].fields[fieldId]?.constraints.includes(Constraints.PRIMARY_KEY)})} onClick={() => switchPrimaryKey(tableId, fieldId)}><Key selected={tables[tableId].fields[fieldId]?.constraints.includes(Constraints.PRIMARY_KEY)}/></div>
                                        <div className={st.tableOptionButton} onClick={(e) => deleteField(e, tableId, fieldId)}><Trash selected={tableId === selectedTableId}/></div>
                                    </div>

                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            
        </div>
    )
}

export default WorkBench;

export const Constraints = {
    PRIMARY_KEY: 'primary_key',
    FOREIGN_KEY: 'foreign_key'
};
