import {NotificationManager} from "react-notifications";

export const createNotification = (type, message, title) => {
    switch (type) {
        case NotificationStatus.INFO:
            NotificationManager.info(message ? message : 'Уведомление', title ? title : '', 8000);
            break;
        case NotificationStatus.SUCCESS:
            NotificationManager.success(message ? message : 'Успех', title ? title : '', 8000);
            break;
        case NotificationStatus.WARNING:
            NotificationManager.warning(message ? message : 'Подробнее в консоли', title !== null ? title : 'Неизвестное предупреждение', 8000);
            break;
        case NotificationStatus.ERROR:
            NotificationManager.error(message ? message : 'Подробнее в консоли', title ? title : 'Неизвестная ошибка', 8000);
            break;
    }
};

export const NotificationStatus = {
    INFO: 'info',
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'error'
};