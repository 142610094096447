import React from 'react';

const Options = ({selected}) => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="14" cy="14" r="2" fill={selected ? "#295bd5" : "#A3A2A2"}/>
            <circle cx="14" cy="6" r="2" fill={selected ? "#295bd5" : "#A3A2A2"}/>
            <circle cx="14" cy="22" r="2" fill={selected ? "#295bd5" : "#A3A2A2"}/>
        </svg>
    );
};

export default Options;