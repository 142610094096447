import './App.css';
import {Redirect, Route, Switch} from "react-router-dom";
import {Editor} from "./pages/Editor/Editor";
import {Auth} from "./pages/Auth";
import {Register} from "./pages/Register/Register";
import NotificationContainer from "react-notifications/lib/NotificationContainer";

function App() {
  return (
    <div className="App">
      <Switch>
        <Route path="/database-editor"><Editor/></Route>
        <Route path="/auth"><Auth/></Route>
        <Route path="/register"><Register/></Route>
        <Redirect to="/database-editor"/>
      </Switch>
    <NotificationContainer/>

    </div>
  );
}

export default App;
