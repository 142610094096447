import React from 'react';

const TableTopBorderSelect = () => {
    return (
        <svg x={4} width="202" height="7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.5 7V6.5C0.5 3.18629 3.18629 0.5 6.5 0.5H195.5C198.814 0.5 201.5 3.18629 201.5 6.5V7" stroke="#414AFF"/>
        </svg>
    );
};

export default TableTopBorderSelect;